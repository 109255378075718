<template>
  <div>
    <span
      class="link"
      @click="backStep"
    > Назад </span>
    <div id="cropBanner">
      <input
        ref="input"
        class="cropImgUpload"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
      >
      <div class="contentCrop">
        <section class="cropper-area">
          <div class="img-cropper">
            <vue-cropper
              ref="cropper"
              :view-mode="1"
              :aspect-ratio="bannerType == 'mobile_url' ? 1 / 1 : 3 / 1"
              :src="imgSrc"
              preview=".preview"
            />
          </div>
          <div class="actionsCrop">
            <a
              href="#"
              role="button"
              @click.prevent="rotate(90)"
            >
              <feather-icon icon="RotateCcwIcon" />
            </a>
            <a
              href="#"
              role="button"
              @click.prevent="rotate(-90)"
            >
              <feather-icon icon="RotateCwIcon" />
            </a>
            <a
              ref="flipX"
              href="#"
              role="button"
              @click.prevent="flipX"
            >
              <feather-icon icon="ColumnsIcon" />
            </a>
            <a
              ref="flipY"
              href="#"
              role="button"
              @click.prevent="flipY"
            >
              <feather-icon icon="MinusSquareIcon" />
            </a>

            <a
              ref="flipY"
              href="#"
              role="button"
              @click.prevent="reset"
            >
              <feather-icon icon="RefreshCcwIcon" />
            </a>
          </div>
          <b-button
            variant="primary"
            class="mr-1"
            @click.prevent="showFileChooser"
          >
            Boshqa rasm yuklash
          </b-button>
        </section>
        <section class="preview-area">
          <p>Ko'rinishi</p>
          <div class="preview" />
          <div class="cropped-image">
            <img
              v-if="cropImg"
              :src="cropImg"
              alt="Cropped Image"
            >
          </div>
        </section>
      </div>
    </div>
    <div class="mt-2 text-right">
      <b-button
        variant="success"
        class="mr-1"
        @click="cropImage"
      >
        Saqlash
      </b-button>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'

export default {
  components: {
    VueCropper,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    bannerForm: {
      type: Object,
      default: () => {},
    },
    bannerType: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      // imageModal: true,
      imgSrc: '/images/banner.jpg',
      aspectRatio: '3 / 1',
      cropImg: '',
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  mounted() {
    this.showFileChooser()
    if (this.bannerType && this.bannerType == 'mobile_url') {
      this.aspectRatio = '1 / 1'
    } else if (this.bannerType && this.bannerType == 'desktop_url') {
      this.aspectRatio = '3 / 1'
    }
  },
  methods: {
    ...mapActions({
      fileUpload: 'client/fileUpload',
      addBanner: 'shop/store',
    }),
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      const dataURL = this.$refs.cropper.getCroppedCanvas().toDataURL()
      // const parts = dataURL.split(';')
      // const mimeType = parts[0].split(':')[1]
      // const base64Data = dataURL.split(',')[1]
      // console.log(base64Data, mimeType, dataURL)

      // const blob = new Blob([base64Data], { type: mimeType })
      const filename = 'cropped-image.png'
      // const croppedImageFile = new File([blob], filename, { type: mimeType })
      // console.log(croppedImageFile)
      const arr = dataURL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[arr.length - 1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      const croppedImageFile = new File([u8arr], filename, { type: mime })
      console.log(croppedImageFile, mime)
      this.uploadFile(croppedImageFile)
    },
    backStep() {
      this.$emit('backStep')
    },
    async uploadFile(file) {
      if (file.size <= 15360000) {
        this.loading = true
        const fileData = new FormData()
        fileData.append('file', file)
        fileData.append('directory', 'photo')
        this.fileUpload(fileData)
          .then(res => {
            this.$emit('upload', res.data.path)
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 15 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    flipX() {
      const dom = this.$refs.flipX
      let scale = dom.getAttribute('data-scale')
      scale = scale ? -scale : -1
      this.$refs.cropper.scaleX(scale)
      dom.setAttribute('data-scale', scale)
    },
    flipY() {
      const dom = this.$refs.flipY
      let scale = dom.getAttribute('data-scale')
      scale = scale ? -scale : -1
      this.$refs.cropper.scaleY(scale)
      dom.setAttribute('data-scale', scale)
    },

    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg)
    },

    setImage(e) {
      const file = e.target.files[0]
      console.log(e, file)
      if (file.type.indexOf('image/') === -1) {
        // alert('Please select an image file')
        showToast('danger', 'Iltimos jpg, png formatda rasm yuklang', 'XIcon')
        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = event => {
          this.imgSrc = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }

        reader.readAsDataURL(file)
      } else {
        // alert('Sorry, FileReader API not supported')
        showToast('danger', 'Sorry, FileReader API not supported', 'XIcon')
      }
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
  },
}
</script>

<style lang="scss">
#cropBanner {
  .cropImgUpload {
    display: none;
  }

  .contentCrop {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .cropper-area {
    width: 814px;
  }

  .actionsCrop {
    margin-top: 1rem;
  }

  .actionsCrop a {
    display: inline-block;
    padding: 5px 15px;
    background: #0062CC;
    color: white;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 3px;
    margin-bottom: 1rem;
  }

  .preview-area {
    width: 307px;
  }

  .preview-area p {
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: 1rem;
  }

  .preview {
    width: 100%;
    height: calc(372px * (9 / 16));
    overflow: hidden;
  }

  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }

  .cropped-image img {
    max-width: 100%;
  }
}
</style>
